<template>
	<v-dialog v-model="dialog">
		<template v-slot:activator="{ props }">
			<v-btn color="primary" v-bind="props"> Suggest New Question </v-btn>
		</template>
		<v-card flat class="pa-2" title="Suggest New Question">
			<v-card-text>
				<v-form ref="form">
					<v-select
						label="Question Location"
						:modelValue="location"
						@update:modelValue="(v) => (location = v)"
						:items="sections"
						item-title="name"
						item-value="id"
						:return-object="false"
					></v-select>
					<v-textarea label="Rationale" v-model="rationale"></v-textarea>
					<v-textarea
						label="Comment / text suggestion"
						v-model="suggestion"
					></v-textarea>

					<v-select
						label="Answer Type"
						:modelValue="answer_options"
						@update:modelValue="(v) => (answer_options = v)"
						:items="answer_option_items"
						item-title="text"
						item-value="value"
						:return-object="false"
					></v-select>
					<p v-if="answer_options == 'other'">
						Please include your suggested answer options in the comment box
					</p>
				</v-form>
			</v-card-text>
			<v-card-actions>
				<v-btn color="secondary" @click="dialog = false">Cancel</v-btn>
				<v-spacer></v-spacer>
				<v-btn color="success" size="small" @click="addComment">Submit</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script setup>
import { useSectionsStore } from '@/stores/sections';
import { useCommentsStore } from '@/stores/comments';
import { defineProps, ref, computed } from 'vue';

const sectionsStore = useSectionsStore();
const commentsStore = useCommentsStore();
const props = defineProps({
	section: String
});

const answer_option_items = [
	{ value: 'likert', text: 'Likert Scale (agreement)' },
	{ value: 'percent', text: 'Percent Scale' },
	{ value: 'text', text: 'Free Text' },
	{ value: 'other', text: 'Other' }
];

let sections = computed(() => {
	return [{ id: 'new', name: 'New Section' }].concat(sectionsStore.collection);
});

let form = ref(null);
let dialog = ref(false);

const type = 'add';
let location = ref(props.section);
let answer_options = ref(null);
let rationale = ref(null);
let suggestion = ref(null);
const reset = () => {
	dialog.value = false;
	form.value.reset();
};
const addComment = () => {
	commentsStore
		.create({
			type: type,
			rationale: rationale.value,
			suggestion: suggestion.value,
			location: location.value,
			answer_options: answer_options.value
		})
		.then(() => {
			reset();
		});
};
</script>
