<template>
	<span class="pa-1">
		<v-dialog v-model="dialog">
			<template v-slot:activator="{ props }">
				<v-btn icon color="primary" v-bind="props"> <v-icon>mdi-pencil</v-icon> </v-btn>
			</template>
			<v-card>
				<v-card-title> Edit Question </v-card-title>
				<v-card-text>
					<v-form>
						<question-form
							v-model:question_id="question_id"
							v-model:status="status"
							v-model:question_text="question_text"
							v-model:subquestion_text="subquestion_text"
							v-model:tooltip="tooltip"
							v-model:notes="notes"
							v-model:conditions="conditions"
							v-model:type="type"
						></question-form>
					</v-form>
				</v-card-text>
				<v-card-actions>
					<v-btn color="secondary" @click="dialog = false">Cancel</v-btn>
					<v-spacer></v-spacer>
					<v-btn color="primary" @click="submit">Submit</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</span>
</template>

<script setup>
import { ref, computed, defineProps, watch } from 'vue';
import { useQuestionsStore } from '@/stores/questions';
import QuestionForm from '@c/admin/QuestionForm';
const questionsStore = useQuestionsStore();

const props = defineProps({
	id: String
});

let dialog = ref(false);
let question_id = ref(null);
let status = ref(null);
let question_text = ref(null);
let subquestion_text = ref(null);
let tooltip = ref(null);
let notes = ref(null);
let conditions = ref(null);
let type = ref(null); 


let question = computed(() => {
	return questionsStore.data[props.id];
});

watch( question, (value) => {
	if( value ){
		question_id.value = value.question_id || question_id.value;
		status.value = value.status || status.value;
		question_text.value = value.question_text || question_text.value;
		subquestion_text.value = value.subquestion_text || subquestion_text.value;
		tooltip.value = value.tooltip || tooltip.value;
		notes.value = value.notes || notes.value;
		conditions.value = value.conditions || conditions.value;
		type.value = value.type || type.value;
	}
}, {immediate: true, deep: true } )


function submit() {
	let doc = {
		question_id: question_id.value,
		status: status.value,
		question_text: question_text.value,
		subquestion_text: subquestion_text.value,
		tooltip: tooltip.value,
		notes: notes.value,
		conditions: conditions.value,
		type: type.value,
	};
	questionsStore
		.update( props.id, doc )
		.then(() => {
			dialog.value = false;
		});
}
</script>
