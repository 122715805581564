<template>
	<v-sheet class="pa-2" v-if="haveComments">
		<v-expansion-panels v-model="expanded">
			<v-expansion-panel :value="1" :elevation="0" class="comments">
				<v-expansion-panel-title>
					{{ title }}
				</v-expansion-panel-title>
				<v-expansion-panel-text>
					<comment-list-item 
						v-for="(comment, i) in comments"
						:key="i"
						v-bind="comment"
					></comment-list-item>
				</v-expansion-panel-text>
			</v-expansion-panel>
		</v-expansion-panels>
	</v-sheet>
</template>
<style lang="less">
.comments {
	.v-expansion-panel-title {
		padding: 0px 5px !important;
		font-size: 0.8em;
	}
	.v-expansion-panel-text__wrapper {
		padding: 0px !important;
	}
	.v-expansion-panel-title--active,
	.v-expansion-panel-title:focus,
	.v-expansion-panel-title:active,
	.v-expansion-panel-title:hover {
		background-color: transparent !important;
	}
	.v-expansion-panel-title__overlay {
		display: none;
	}
}
</style>
<script setup>
import { defineProps, computed, ref } from 'vue';
import { useCommentsStore } from '@/stores/comments';
import CommentListItem from "@c/editor/CommentListItem"
const commentsStore = useCommentsStore();
let expanded = ref(1);
let title = computed(() =>
	expanded.value ? 'Hide Comments' : 'Show Comments'
);

const props = defineProps({
	id: String
});

let comments = computed(() => {
	return commentsStore.byQuestion(props.id);
});

let commentCount = computed(() => comments.value.length);
let haveComments = computed(() => commentCount.value > 0);
</script>
