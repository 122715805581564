<template>
	<div class="font-weight-light pa-4">
		<v-row class="about">
			<v-col>
				<h2 class="font-weight-light mb-4">Welcome</h2>
				<p>
					This tool is intended to facilitate comments on the question catalogue
					for the 2023 Scottish Government Health and Care Digital Maturity
					Assessment.
				</p>
			</v-col>
		</v-row>
		<v-row>
			<v-col lg="7" sm="12" class="text-justify">
				<h2 class="font-weight-light mb-4">Assessment Structure</h2>
				<p>
					The Digital Maturity assessment process is divided into three
					high-level "Themes": Readiness, Capabilities and Enabling
					Infrastructure. Themes are divided into a number of sections, each of
					which addresses a specific topic of interest to Digital Maturity.
				</p>
				<p>Each question consists of:</p>
				<ul class="ml-12 mt-4">
					<li>A unique identifier</li>
					<li>A theme (Readiness, Capabilities, Infrastructure) assignment</li>
					<li>A section assignment</li>
					<li>
						A care setting (Acute, Mental Health, Social Care, …) assignment
						indicating which care settings the question should be presented to
						(questions will be hidden from other care settings)
					</li>
					<li>
						If applicable, a dependency statement (e.g., show this question if
						answer to question [Question ID] equals XYZ)
					</li>
					<li>A main question text</li>
					<li>If applicable, sub question text</li>
					<li>
						A tooltip text written to further explain the question text or offer
						examples or possible approaches for answering the question
					</li>
				</ul>
			</v-col>

			<v-col class="d-none d-md-inline">
				<v-img
					class="mx-auto mt-12 ml-6 mr-6"
					src="../assets/images/guide1.png"
				></v-img>
			</v-col>
		</v-row>
		<v-row>
			<v-col class="text-justify">
				<h2 class="font-weight-light mb-4">How To Use This Tool</h2>

				<p>
					You can navigate to the assessment section in which you’d like to
					review questions using the navigation menu on the left-hand side of
					the screen
				</p>
				<p>
					All questions in the section you selected will be displayed within the
					right-hand area, along with additional information about the question
				</p>
				<p>
					To <b>review previous comments</b> or to leave a comment on a question
					yourself, click on the question you’re interested in to access the
					context menu, which will open on the right-hand side of the page.
				</p>
				<p>
					Previous comments will be displayed underneath the question
					information
				</p>
				<p>
					To <b>add your own comment</b>, click on “hide comments” and then
					select “Add Comment” to access the comments form.
				</p>
				<p>
					Select the type of suggestion you’re making. Options available are:
				</p>
				<ul class="ml-12 mt-4">
					<li>Edit – you are suggesting making changes to the question</li>
					<li>
						Remove – you are suggesting that the question be removed entirely
					</li>
					<li>
						Move – you are suggesting that the question should be moved to
						another assessment section (including a new section)
					</li>
				</ul>

				<p>
					To suggest <b>adding a new question</b> not yet available in the
					question catalogue, click on “Add a Question” underneath the last
					question in any of the sections.
				</p>
			</v-col>
		</v-row>
		<v-row>
			<v-col class="text-justify">
				<h2 class="font-weight-light mb-4">Contact</h2>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<p>
					To contact Scottish Government, please get in touch with Marion Logan:

				</p>
				<p>
					<b>Marion Logan (she/her)</b><br />
					Policy Team Leader<br />
					Digital Leadership and Capabilities<br />
					Digital Health and Care Directorate<br />
					<a href="mailto:Marion.Logan@gov.scot">Marion.Logan@gov.scot</a><br />
				</p>
				<v-img
					class="mt-12"
					max-width="300"
					src="../assets/images/logo.png"
				></v-img>
			</v-col>
			<v-col>
				<p>To contact Meisterworks, please get in touch with Henrik Moeller:</p>
				<p>
					<b>Henrik Moeller (he/him)</b><br />
					Director<br />
					<a href="mailto:henrik.moeller@m.works">henrik.moeller@m.works</a
					><br />
					<a href="https://m.works">https://m.works</a>
				</p>
				<v-img
					class="mt-12"
					max-width="300"
					src="../assets/images/mwlogo.png"
				></v-img>
			</v-col>
		</v-row>
	</div>
</template>

<style></style>
