<template>
	<span class="pa-1">
		<v-dialog v-model="dialog">
			<template v-slot:activator="{ props }">
				<v-btn color="primary" v-bind="props">Import Questions</v-btn>
			</template>
			<v-card title="Import Questions">
				<v-card flat class="mb-4" style="margin-bottom: 55px">
					<v-card-text>
						<template v-if="step == 1">
							<p>
								Paste tab delimited:
								{{ headers.join(', ') }}
							</p>
							<v-textarea v-model="rawdata"></v-textarea>
							<v-select :items="['add', 'update']" v-model="mode"></v-select>
						</template>
						<template v-if="step == 2">
							<v-table height="500px">
								<thead>
									<tr>
										<th v-for="header in headers" :key="header">
											{{ header }}
										</th>
										<th>&nbsp;</th>
									</tr>
								</thead>
								<tbody>
									<import-contents
										v-for="(item, i) in items"
										@input="(k, v) => update(i, k, v)"
										:headers="headers"
										:value="item"
										@delete="remove(i)"
										:key="i"
									></import-contents>
								</tbody>
							</v-table>
						</template>
						<template v-if="step == 3">
							<p v-if="countValid">
								Inserting records for {{ countValid }} rows
							</p>
							<div v-if="invalidRecords.length">
								<p>
									The following records failed validation and will not be
									inserted:
								</p>
								<ul>
									<li v-for="(item, i) in invalidRecords" :key="i">
										{{ item.title }}
									</li>
								</ul>
							</div>
						</template>
					</v-card-text>
					<v-card-actions
						style="
							position: absolute;
							bottom: 0px;
							background: white;
							width: 100%;
						"
					>
						<v-btn text @click="clear"> Cancel </v-btn>

						<v-spacer></v-spacer>
						<v-btn class="mr-1" color="secondary" @click="step--"> Back </v-btn>
						<v-btn color="primary" @click="submit" v-if="step == 3">
							Submit
						</v-btn>
						<v-btn color="primary" @click="step++" v-else> Next </v-btn>
					</v-card-actions>
				</v-card>
			</v-card>
		</v-dialog>
	</span>
</template>

<style lang="less">
.v-stepper.transparent {
	background: transparent;
	.v-stepper__header {
		box-shadow: none;
	}
}
</style>
<script setup>
import { defineProps, ref, computed, watch } from 'vue';
import ImportContents from '@c/admin/ImportContents';
import { useQuestionsStore } from '@/stores/questions';
const props = defineProps({
	section: String
});
let dialog = ref(false);
let step = ref(1);
let rawdata = ref(null);
let mode = ref('add');
let items = ref([]);

const questionsStore = useQuestionsStore();
const headers = questionsStore.fields;

let countValid = computed(() => {
	return items.value.filter((item) => item.question_text).length;
});

let invalidRecords = computed(() => {
	return items.value.filter((item) => !item.question_text);
});

const remove = (i) => {
	items.value.splice(i, 1);
};

const update = (i, k, v) => {
	let item = items.value[i];
	item[k] = v;
	items.value[i] = item;
};

const bools = [
	'acute',
	'community',
	'mental_health',
	'adult_social_care',
	'childrens_social_care',
	'ambulance',
	'primary_care',
	'staff_survey'
];

const clear = () => {
	step.value = 1;
	items.value = [];
	rawdata.value = null;
	dialog.value = false;
};
const isHeader = (row) => {
	row = row.map((r) => r.trim());
	if (JSON.stringify(row) == JSON.stringify(headers)) {
		return true;
	}
	return false;
};

watch(rawdata, (v) => {
	if (v) {
		items.value = makeItems(v);
	}
});

const makeItems = (raw) => {
	raw = raw.split('\n');
	// raw.splice(11);
	// raw = raw.map((r) => r.split(\t));
	let items = [];
	raw = raw.filter((r) => r);
	raw.forEach((line) => {
		let row = Object.fromEntries(headers.map((h) => [h, '']));
		line = line.split('\t');
		if (!line) {
			return;
		}
		if (isHeader(line)) {
			return;
		}
		headers.forEach((h, i) => {
			row[h] = line[i] || row[h];
		});
		bools.forEach((r) => (row[r] = row[r] == 'TRUE' ? true : false));

		items.push(row);
	});
	return items;
};

const submit = () => {
	// let i = items.value.filter((i) => i.valid);
	if (mode.value == 'add') {
		items.value.forEach((item) => {
			questionsStore.create(item, props.section);
		});
	}
	if (mode.value == 'update') {
		items.value.forEach((item) => {
			questionsStore.updateByQuestionId(item);
		});
	}
	clear();
};
//
</script>
