<template>
	<span>
		<v-btn icon @click="toggleChat">
			<v-icon>mdi-chat</v-icon>
		</v-btn>
		<div id="mwChatWrapper"></div>
	</span>
</template>

<style lang="less">
#intergramRoot {
	div {
		right: auto !important;
		right: 70px !important;
		box-shadow: none !important;
		div {
			font-weight: lighter !important;
			font-size: 18px;
			// visibility: hidden;
			svg {
				// visibility: hidden;
			}
		}
	}
}
</style>

<script>
export default {
	name: "LiveChat",
	props: {
		expanded: { type: Boolean, default: true }, // is this currently expanded
	},
	data: () => {
		return {
			date: false,
			added: false,
			opened: false,
		};
	},
	computed: {
		chat() {
			return ( {
					title_closed: "Chat",
					title_open: "Live chat",
					intro_message: "Hello. How can I help you?",
					auto_response: "Thank you for your message. An operator will respond shortly",
					auto_noresponse: "Sorry it's taking us longer than usual to respond. ",
					floating_button: "false",
					color: "#1478C6",
				}
			);
		},
		intergramId() {
			return this.chat.id;
		},
		intergramCustomizations() {
			return {
				titleOpen: this.chat.title_open,
				titleClosed: this.chat.title_closed,
				introMessage: this.chat.intro_message,
				autoResponse: this.chat.auto_response,
				autoNoResponse: this.chat.auto_noresponse,
				mainColor: this.chat.color,
				alwaysUseFloatingButton: false,
			};
		},
		// showChat() {
			// if (!this.chat.show) {
			// 	return false;
			// }
			// if (!this.chat.timed) {
			// 	return true;
			// }
			// var time = Vue.moment(this.date).format("HH:mm");
			// if (time > this.chat.start && time < this.chat.end) {
			// 	return true;
			// } else {
			// 	return false;
			// }
		// },
	},
	watch: {
		// user: {
		// 	immediate: true,
		// 	handler() {
		// 		if (!window.intergramOnOpen) {
		// 			window.intergramOnOpen = {
		// 				visitorName: "visitor",
		// 			};
		// 		}
		// 		if (this.user) {
		// 			window.intergramOnOpen.visitorName = this.user.email;
		// 		}
		// 	},
		// },
		// showChat: {
		// 	immediate: true,
		// 	handler() {
		// 		// if( this.showChat ){
		// 		// 	this.startChat();
		// 		// }
		// 	},
		// },
	},
	methods: {
		toggleChat() {
			if (!this.added) {
				this.startChat();
			} else {
				this.openChat();
			}
		},
		startChat() {
			const self = this;
			var chatScript = document.createElement("script");
			chatScript.setAttribute("src", "https://www.intergram.xyz/js/widget.js");
			chatScript.setAttribute("id", "intergram");
			window.intergramId = "-437300287";
			window.intergramCustomizations = this.intergramCustomizations;
			document.body.appendChild(chatScript);
			setTimeout(function() {
				dispatchEvent(new Event("load"));
				self.added = true;
				self.openChat();
			}, 200);
		},
		openChat() {
			let intergramRoot = document.getElementById("intergramRoot");
			if (intergramRoot) {
				let container = intergramRoot.querySelector("div");
				let button = container.querySelector("div");
				button.click();
				this.opened = true;
			} else {
				this.startChat();
			}
		},
	},
	created() {
		// this.startChat();
		this.date = new Date();
	},
};
</script>
