import { defineStore } from 'pinia';
import { useCollection } from 'vuefire';
import { usersRef, functions, getDocRef } from '../../firebase';
import { addDoc, query, where, updateDoc } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { computed } from 'vue';
import { useAuthStore } from './auth';
import { useDmaStore } from './dma';
const createAuthUser = httpsCallable(functions, 'createAuthUser');

export const useUsersStore = defineStore('users', () => {
	const dma = useDmaStore();
	const collection = useCollection(query(usersRef, where('dma', '==', dma.id)));
	const data = computed(() => {
		return Object.fromEntries(
			collection.value.map((v) => {
				v.ref = v.id;
				return [v.id, v];
			})
		);
	});
	const create = async (doc) => {
		const dma = useDmaStore();
		const auth = useAuthStore();
		doc.created_at = new Date();
		doc.created_by = auth.id;
		doc.dma = dma.id;
		doc.dmas = [dma.id];
		doc.role = 'user';
		let user = await addDoc(usersRef, doc);
		return await createAuthUser({
			uid: user.id,
			displayName: doc.name,
			email: doc.email
		});
	};

	const update = async (id, update) => {
		const auth = useAuthStore();
		update.updated_at = new Date();
		update.updated_by = auth.id;

		return await updateDoc(getDocRef('users', id), update);
	};

	return { collection, data, create, update };
});
