<template>
	<span>
		<v-btn :size="size" icon @click="confirming = true" v-if="!confirming">
			<v-icon>{{ icon }}</v-icon>
		</v-btn>
		<v-progress-circular
			:size="progressSize"
			width="4"
			indeterminate
			v-if="confirming"
			color="success"
		>
			<v-btn :size="size" icon @click="confirm">
				<v-icon color="success"> mdi-check </v-icon>
			</v-btn>
		</v-progress-circular>
	</span>
</template>

<script setup>
import { defineProps, defineEmits, ref, watch, computed } from 'vue';
const emit = defineEmits(['click']);
const props = defineProps({
	icon: String,
	size: {type: String, default: "small"}
});

let confirming = ref(false);
const reset = () => {
	confirming.value = false;
};
const confirm = () => {
	emit('click', true);
	reset();
};

let progressSize = computed( () => {
	let s = 44;
	if( props.size == "x-small" ){
		s = 35
	}
	if( props.size == "large" ){
		s = 53
	}
	return s;
})

watch(confirming, (value) => {
	if (value) {
		setTimeout(function () {
			reset();
		}, 3000);
	}
});
</script>
