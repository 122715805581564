import { defineStore } from 'pinia';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { getAuth, signOut } from 'firebase/auth';
import router from '@/router';
import { useDmaStore } from '@/stores/dma';
import { useDmasStore } from '@/stores/dmas';

export const useAuthStore = defineStore({
	id: 'auth',
	state: () => {
		return {
			name: '',
			docRef: null,
			id: null,
			email: null,
			phone: null,
			last_login: null,
			created_at: null,
			role: 'user',
			registration_type: 'user',
			dma: null, 
			dmas: []
		};
	},
	getters: {
		loggedIn: (state) => (state.id ? true : false),
		dmaId: (state) => (state.dma || false),
		isAdmin: (state) => (state.role == "admin")
	},
	actions: {
		async initialDocInsertion(authObj) {
			let user = {
				email: authObj.email,
				id: authObj.uid,
				name: authObj.displayName || this.name,
				phone: authObj.phone || this.phone,
				created_at: new Date(),
				last_login: new Date(),
				registration_type: this.registration_type
			};
			setDoc(this.docRef, user).then(() => {
				this.setData(user);
			});
		},
		async init(authObj) {
			this.id = authObj.uid;
			this.docRef = doc(db, 'users', this.id);
			let user = await getDoc(this.docRef);
			if (user.exists()) {
				this.setData(user.data());
				this.fetchDma()
			} else {
				this.initialDocInsertion(authObj);
			}
			return;
		},
		fetchDma(){
			const dmasStore = useDmasStore();
			this.dmas.forEach( v => {
				dmasStore.fetchById(v);
			})
			const dmaStore = useDmaStore();
			if(this.dma){
				dmaStore.init( this.dma )
			}
		},
		setData(obj) {
			for (var key in obj) {
				this[key] = obj[key];
			}
		},
		redirectToDashboard() {
			router.push({ name: 'home' });
		},
		logout() {
			signOut(getAuth()).then(() => {
				this.$reset();
				router.replace('/');
			});
		},
		async updateDma(id){
			let update = {
				updated_at: new Date(),
				updated_by: this.id,
				dma: id
			}
			return await updateDoc(this.docRef, update);
		}
		// increment() {
		//   this.counter++
		// },
		// initialInsertion(){
		// }
	}
});
