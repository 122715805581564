<template>
	<span>
		<v-btn v-if="!auth.id" router-link to="/login" color="black">Log In</v-btn>
		<span v-else>
			Welcome {{auth.name}}
		</span>
	</span>
</template>

<script async setup>
import { getCurrentUser } from 'vuefire';
import { useAuthStore } from '@/stores/auth';
import { onMounted } from 'vue';
let user = await getCurrentUser();
const auth = useAuthStore();
onMounted(() => {
	if (user) {
		auth.init(user);
	}
});
</script>
