<template>
	<v-app-bar color="white">
		<v-img
			class="ml-auto"
			alt="Digital Health and Social Care Scotland Logo"
			router-link
			to="/"
			src="../../assets/images/logo.png"
			max-width="200px"
		></v-img>
		<v-spacer></v-spacer>
		<v-btn
			v-if="auth.isAdmin"
			router-link
			to="/admin"
			variant="text"
			size="small"
			class="mr-2"
			>admin</v-btn
		>
		<Suspense>
			<welcome-nav></welcome-nav>
		</Suspense>
		<template #fallback> Loading... </template>
		<live-chat></live-chat>
		<log-out v-if="auth.id"></log-out>
	</v-app-bar>
</template>
<script setup>
import WelcomeNav from '@c/auth/WelcomeNav';
import LogOut from '@c/auth/LogOut';
import LiveChat from '@c/notices/LiveChat';
import { useAuthStore } from '@/stores/auth';
const auth = useAuthStore();
</script>
