<template>
	<div class="about mt-7">
		<v-row>
			<v-col lg="8" md="6" sm="12" class="text-justify">
				<h2 class="font-weight-light mb-12">Welcome to the DHCS DMA Question Tool</h2>
				<p>
					This website is a tool for internal and external stakeholders working on
					the development of the 2023 Digital Maturity Assessment.
				</p>
				<p>
					If you have been provided with login details, please enter them using the
					login button near the top right of this screen. If you have any questions
					or experience problems with logging in, please contact <a href="mailto:dhcs@m.works">support</a>. For more
					information on the Digital Maturity Assessment, please get in touch with
					us.
				</p>
				<p>
					Please note that this website is intended for internal use by the health
					and care organisations under Scottish Government’s purview only and is not
					accessible to the public.
				</p>
				<p>
					If you have any general questions or comments, please contact <a href="mailto:dhcs@m.works">Digital Health & Care Scotland</a
					>, we will be happy to answer your queries or put you in touch with a
					member of staff who can assist you.
				</p>
			</v-col>
			<v-col class="d-none d-md-inline">
				<v-img class="mx-auto mt-12" max-width="300" src="https://x.m.works/img/secure_remote_sessions.4296cb58.png"></v-img>
			</v-col>
		</v-row>
	</div>
</template>

<style>
  .about {
    font-size: 1.4em;
    font-weight: 300;
    line-height: 1.7em;
  }
</style>