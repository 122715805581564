import { defineStore } from 'pinia';
import { getDoc, getDocs } from 'firebase/firestore';
import { getDocRef, dmasRef } from '../../firebase';
import { ref, computed } from 'vue';
export const useDmasStore = defineStore('dmas', () => {
	const data = ref({});
	const fetchAll = async () => {
		let snap = await getDocs(dmasRef);
		if (!snap.empty) {
			snap.docs.forEach((doc) => {
				data.value[doc.id] = { id: doc.id, ...doc.data() };
			});
		}
	};
	const fetchById = async (id) => {
		const docRef = getDocRef('dmas', id);
		let dma = await getDoc(docRef);
		if (dma.exists()) {
			data.value[id] = dma.data();
		} else {
			data.value[id] = null;
		}
		return;
	};
	const collection = computed(() =>
		Object.keys(data.value).map((k) => ({ id: k, ...data.value[k] }))
	);
	return { collection, data, fetchById, fetchAll };
});
