import { defineStore } from 'pinia';
import { useCollection } from 'vuefire';
import { addDoc, arrayUnion, arrayRemove, updateDoc } from 'firebase/firestore';
import { sectionsRef, getDocRef } from '../../firebase';
import { computed } from 'vue';
import { useAuthStore } from './auth';
import { useDmaStore } from './dma';
export const useSectionsStore = defineStore('sections', () => {
	const collection = useCollection(sectionsRef);
	const data = computed(() => {
		return Object.fromEntries(
			collection.value.map((v) => {
				v.ref = v.id;
				return [v.id, v];
			})
		);
	});
	const create = async (doc) => {
		const auth = useAuthStore();
		const dma = useDmaStore();
		doc.created_at = new Date();
		doc.created_by = auth.id;
		doc.questions = [];
		let newRef = await addDoc(sectionsRef, doc);
		return await dma.addSection(newRef.id);
	};
	const update = async (id, doc) => {
		const auth = useAuthStore();
		doc.updated_at = new Date();
		doc.updated_by = auth.id;
		return await updateDoc(getDocRef('sections', id), doc);
	};
	const addQuestionToSection = async (section, question ) => {
		const auth = useAuthStore();
		let update = {
			updated_at: new Date(),
			updated_by: auth.id
		}
		update.questions = arrayUnion(question);
		return await updateDoc(getDocRef('sections', section), update );
	};
	const deleteQuestionFromSection = async (section, question) => {
		const auth = useAuthStore();
		let update = {
			updated_at: new Date(),
			updated_by: auth.id
		}
		update.questions = arrayRemove(question);
		return await updateDoc(getDocRef('sections', section), update );
	};
	return { collection, data, create, update, addQuestionToSection, deleteQuestionFromSection };
});
