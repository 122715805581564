<template>
	<v-navigation-drawer
		:value="value"
		app
		permanent
		location="right"
		style="z-index: 100"
		width="450"
	>
		<v-list dense>
			<slot></slot>
		</v-list>
		<template v-slot:append>
			<nav-item
				@click="$emit('close')"
				text="Hide Menu"
				icon="mdi-menu"
			></nav-item>
		</template>
	</v-navigation-drawer>
</template>
<script>
import NavItem from '@c/navigation/Item';
export default {
	props: {
		value: Boolean
	},
	components: {
		NavItem
	}
}
</script>
