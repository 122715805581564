<template>
	<v-sheet>
		<question-overview v-bind="question"></question-overview>
		<comments-list :id="id"></comments-list>
		<div class="ma-1">
			<comments-form
				v-if="canEdit"
				:question="id"
				:section="section"
				block
			></comments-form>
		</div>
	</v-sheet>
</template>

<script setup>
import { computed, defineProps } from 'vue';
import { useDmaStore } from '@/stores/dma';
import { useQuestionsStore } from '@/stores/questions';
import CommentsForm from '@c/editor/CommentsForm';
import CommentsList from '@c/editor/CommentsList';
import QuestionOverview from '@c/editor/QuestionOverview';
const props = defineProps({
	id: String,
	section: String
});
const questionsStore = useQuestionsStore();
let question = computed(() => {
	return questionsStore.data[props.id];
});
const dmaStore = useDmaStore();

let canEdit = computed(() => {
	return dmaStore.status == 'open';
});
</script>
