import { initializeApp } from 'firebase/app';
import { getFirestore, collection, doc, } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
// ... other firebase imports



export const firebaseApp = initializeApp({
	apiKey: "AIzaSyBnCO1anhDQ1VAEBZ1O9vZuOJnrdKvJfZY",
	authDomain: "dma-sc-question.firebaseapp.com",
	projectId: "dma-sc-question",
	storageBucket: "dma-sc-question.appspot.com",
	messagingSenderId: "1076208977432",
	appId: "1:1076208977432:web:0665db8a0c5aa55ef0e56d",
	measurementId: "G-K30Y6NBL5E"
});

// used for the firestore refs
export const db = getFirestore(firebaseApp);
export const functions = getFunctions(firebaseApp, "europe-west1")

// here we can export reusable database references
export const dmasRef = collection(db, 'dmas');
export const sectionsRef = collection(db, 'sections');
export const questionsRef = collection(db, 'questions');
export const commentsRef = collection(db, 'comments');
export const usersRef = collection(db, 'users');
export function getDocRef( collection, id ){
	return doc(db, collection, id);
}