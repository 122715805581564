<template>
	<div class="pa-1">
		<v-dialog v-model="dialog">
			<template v-slot:activator="{ props }">
				<v-btn color="primary" v-bind="props" block> Add User </v-btn>
			</template>
			<v-card flat class="pa-2" title="New User">
				<v-card-text>
					<v-form>
						<v-text-field label="Name" v-model="name"></v-text-field>
						<v-text-field label="Email" v-model="email"></v-text-field>
					</v-form>
				</v-card-text>
				<v-card-actions>
					<v-btn color="secondary" @click="dialog = false">Cancel</v-btn>
					<v-spacer></v-spacer>
					<v-btn color="success" size="small" @click="addUser">Submit</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script setup>
import { useUsersStore } from '@/stores/users';
import { ref } from 'vue';
const usersStore = useUsersStore();

let dialog = ref(false);

let name = ref(null);
let email = ref(null);

const addUser = () => {
	usersStore
		.create({
			name: name.value,
			email: email.value
		})
		.then(() => {
			dialog.value = false;
		});
};
</script>
