<template>

	<v-chip :color="color" :size="size">{{text}}</v-chip>
</template>
    
<script setup>
    import { defineProps, computed } from "vue";

    const props = defineProps({
        value: {type: String, default: ""},
        size: {type: String, default: "default"},
        color: {type: String, default: "primary"}
    })

    let text = computed( () => {
        return props.value.split("-").join(" ").split("_").join(" ");
    } )
</script>