<template>
	<span v-if="user">
        <v-icon>mdi-account</v-icon>
        {{user.name}}
    </span>
</template>

<script setup>
	import { defineProps, computed } from "vue";
	import { useUsersStore } from '@/stores/users';
	const usersStore = useUsersStore();
    
	const props = defineProps({
		id: String
	})

    let user = computed (( ) => {
        return usersStore.data[props.id];
    })
</script>
