<template>
	<div class="pa-1">
		<v-dialog v-model="dialog">
			<template v-slot:activator="{ props }">
				<v-btn block color="success" v-bind="props"> Add Section </v-btn>
			</template>
			<v-card>
				<v-card-title> Create Section </v-card-title>
				<v-card-text>
					<v-form>
						<section-form
							v-model:name="name"
							v-model:theme="theme"
							v-model:description="description"
						></section-form>
					</v-form>
				</v-card-text>
				<v-card-actions>
					<v-btn color="secondary" @click="dialog = false">Cancel</v-btn>
					<v-spacer></v-spacer>
					<v-btn color="primary" @click="submit">Submit</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script setup>
import SectionForm from '@c/admin/SectionForm';
import { useSectionsStore } from '@/stores/sections';
import { ref } from 'vue';
let dialog = ref(false);
let name = ref(null);
let theme = ref(null);
let description = ref(null);
function submit() {
	const sectionStore = useSectionsStore();
	sectionStore
		.create({
			name: name.value,
			theme: theme.value,
			description: description.value,
		})
		.then(() => {
			dialog.value = false;
		});
}
</script>
