import { defineStore } from 'pinia';
import { useCollection } from 'vuefire';
import { addDoc, updateDoc } from 'firebase/firestore';
import { questionsRef, getDocRef } from '../../firebase';
import { computed, ref } from 'vue';
import { useAuthStore } from './auth';
import { useSectionsStore } from './sections';

export const useQuestionsStore = defineStore('questions', () => {
	const fields = [
		'question_id',
		'status',
		'question_text',
		'subquestion_text',
		'tooltip',
		'notes',
		'conditions',
		'type',
	];
	const types = ref([
		{ value: 'likert-scale', name: 'Likert Scale' },
		{ value: 'free-text', name: 'Free Text' },
		{ value: 'percent-scale', name: 'Percent Scale' },
		{ value: 'unstructured', name: 'Unstructured' }
	]);

	const statuses = ref(['new', 'unreviewed', 'under review']);

	const services = ref([]);

	const collection = useCollection(questionsRef);
	const data = computed(() => {
		return Object.fromEntries(
			collection.value.map((v) => {
				v.ref = v.id;
				return [v.id, v];
			})
		);
	});
	
	const create = async (doc, section) => {
		const auth = useAuthStore();
		doc.created_at = new Date();
		doc.created_by = auth.id;
		let newRef = await addDoc(questionsRef, doc);
		if (section) {
			let sections = useSectionsStore();
			return await sections.addQuestionToSection(section, newRef.id);
		} else {
			return true;
		}
	};
	const update = async (id, doc) => {
		const auth = useAuthStore();
		doc.updated_at = new Date();
		doc.updated_by = auth.id;
		return await updateDoc(getDocRef('questions', id), doc);
	};
	const updateByQuestionId = async( doc ) => {
		if( !doc.question_id ){
			return;
		}
		let existingQuestion = collection.value.find( d => d.question_id == doc.question_id );
		if( !existingQuestion ){
			return;
		} 
		doc.id = existingQuestion.id;
		return await update( existingQuestion.id, doc );

	}
	return {
		collection,
		data,
		types,
		create,
		update,
		services,
		fields,
		statuses,
		updateByQuestionId
	}
});
