<template>
	<v-sheet v-if="section">
		<draggable :list="section.questions" item-key="index" @change="saveQuestioninSection"  :disabled="disabledDrag">
		<template #item="{ element, index }">
		<question-list-item
			:key="index"
			:id="element"
			:section="id"
			v-bind="questions[element]"
			:active="current == element"
			@click="$emit('select', element)"
		></question-list-item>
		</template>
		</draggable>
	</v-sheet>
</template>
<script setup>

import { useDmaStore } from '@/stores/dma';
import { useAuthStore } from '@/stores/auth';
import { useQuestionsStore } from '@/stores/questions';
import { useSectionsStore } from '@/stores/sections';
import { computed, defineProps } from 'vue';
import QuestionListItem from '@c/editor/QuestionListItem';

import draggable from 'vuedraggable';

const props = defineProps({
	id: String,
	current: String
})
const dma = useDmaStore();
const questionsStore = useQuestionsStore();
const sectionsStore = useSectionsStore();
const auth = useAuthStore();

let disabledDrag = computed( () => {
	return !(auth.isAdmin && dma.status == 'open')
})

let section = computed(() => {
	return sectionsStore.data[props.id];
});
let questions = computed(() => {
	return questionsStore.data
});

const saveQuestioninSection = () => {
	sectionsStore.update(props.id, {questions: section.value.questions });
};


</script>
