<template>
	<div>
		<v-text-field
			label="Question Text"
			:modelValue="question_text"
			@input="$emit('update:question_text', $event.target.value)"
		></v-text-field>
		<v-row>
			<v-col>
				<v-select
					label="Type"
					:modelValue="type"
					item-title="name"
					item-value="value"
					:return-object="false"
					@update:modelValue="(v) => $emit('update:type', v)"
					:items="questions.types"
				></v-select>
			</v-col>
			<v-col
				><v-select
					label="Status"
					:modelValue="status"
					@update:modelValue="(v) => $emit('update:status', v)"
					:items="questions.statuses"
				></v-select>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<v-textarea
					label="Subquestion"
					:modelValue="subquestion_text"
					@input="$emit('update:subquestion_text', $event.target.value)"
				></v-textarea>
			</v-col>
			<v-col>
				<v-textarea
					label="Tooltip"
					:modelValue="tooltip"
					@input="$emit('update:tooltip', $event.target.value)"
				></v-textarea>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<v-textarea
					label="Visibility Conditions"
					:modelValue="conditions"
					@input="$emit('update:conditions', $event.target.value)"
				></v-textarea>
				<v-text-field
					label="Question ID"
					:modelValue="question_id"
					@input="$emit('update:question_id', $event.target.value)"
				></v-text-field>
			</v-col>
			<v-col>
				<template v-for="e in bools" :key="e">
					<v-checkbox hide-details
						:label="e"
						:modelValue="props[e]"
						@input="$event => $emit(`update:${e}`, $event.target.checked )"
					></v-checkbox>
				</template>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<v-textarea
					label="Notes"
					:modelValue="notes"
					@input="$emit('update:notes', $event.target.value)"
				></v-textarea>
			</v-col>
		</v-row>
	</div>
</template>

<script setup>
import { useQuestionsStore } from '@/stores/questions';
import { defineProps } from 'vue';
const props = defineProps({
	question_id: String,
	status: String,
	question_text: String,
	subquestion_text: String,
	tooltip: String,
	notes: String,
	conditions: String,
	type: String,
});
const bools = [
];
const questions = useQuestionsStore();
</script>
