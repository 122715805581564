<template>
	<v-dialog v-if="canSwitch" v-model="dialog" width="460">
		<template v-slot:activator="{ props }">
			<v-btn v-bind="props" class="mt-1" block color="primary">
				{{ dma.name }}<v-icon>mdi-refresh</v-icon>
			</v-btn>
		</template>

		<v-card>
			<v-card-text>
				<v-table width="100%">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Status</th>
                            <th>Editing</th>
                        </tr>
                    </thead>
					<tr v-for="d in dmas" :key="d.id">
						<td>{{ d.name }}</td>
						<td>
							<span>{{ d.status }}</span>
						</td>
						<td>
							<v-btn icon elevation="0">
								<v-icon @click="selected = d.id">{{
									selected == d.id
										? 'mdi-checkbox-marked'
										: 'mdi-checkbox-blank'
								}}</v-icon>
							</v-btn>
						</td>
					</tr>
				</v-table>
			</v-card-text>
			<v-card-actions>
				<v-btn color="secondary" @click="dialog = false"> Close Dialog </v-btn>
				<v-spacer></v-spacer>
                <v-btn color="primary" @click="changeDma">Submit</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import { useDmasStore } from '@/stores/dmas';
import { useDmaStore } from '@/stores/dma';
import { useAuthStore } from '@/stores/auth';
const auth = useAuthStore();
const dma = useDmaStore();
const dmasStore = useDmasStore();

let dialog = ref(null);
let selected = ref(null);

let dmaId = computed(() => dma.id);
watch(
	dmaId,
	(value) => {
		selected.value = value;
	},
	{ immediate: true }
);

let dmas = computed(() => dmasStore.collection);

let canSwitch = computed(() => dmas.value.length > 0);

const changeDma = () => {
    if( selected.value == dmaId.value ){
        dialog.value = null;
    }
    else {
        auth.updateDma(selected.value).then( () =>{
            window.location.reload();
        } )
    }
}
</script>
