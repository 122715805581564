<template>
	<v-card class="rounded-0 mb-2" :color="active ? 'secondary' : 'transparent'">
		<v-card-title>
			<v-chip size="small" class="mr-1">{{ question_id }}</v-chip>
			<span class="title font-weight-light">
				{{ question_text }}
			</span>
			<question-tooltip v-if="tooltip" :value="tooltip"></question-tooltip>
		</v-card-title>
		<v-card-text>
			<p v-if="subquestion_text" class="mb-3">
				<b>Subquestion</b>: {{ subquestion_text }}
			</p>
			<p class="primary--text mb-2" v-if="conditions">
				<small><b>Visible if:</b></small> <i>{{ conditions }}</i>
			</p>
			<v-row class="mt-8">
				<v-col class="pa-2">
					<small><b>Answer Options: </b></small>
					<type-chip v-if="type" :value="type" size="small"></type-chip>
				</v-col>
				<v-col class="pa-2">
					<small><b>Applies To: </b></small>
					<i>Awaiting confirmation</i>
					<type-chip
						v-for="s in bools"
						:key="s"
						:value="s"
						size="small"
						class="ml-1"
						color="accent"
					></type-chip>
				</v-col>
			</v-row>
		</v-card-text>
		<v-card-actions>
			<comments-for-question :id="id"></comments-for-question>
			<v-spacer></v-spacer>
			<question-admin-tools
				v-if="auth.isAdmin && id"
				:id="id"
				:section="section"
			></question-admin-tools>
		</v-card-actions>
	</v-card>
</template>
<style lang="less">
.v-card-title {
	overflow: auto;
	white-space: break-spaces;
}
</style>

<script setup>
import { useAuthStore } from '@/stores/auth';
import { computed, defineProps } from 'vue';
import TypeChip from '@c/editor/TypeChip';
import QuestionTooltip from '@c/editor/QuestionTooltip';
import CommentsForQuestion from '@c/editor/CommentsForQuestion';
import QuestionAdminTools from '@c/admin/QuestionAdminTools';

const props = defineProps({
	id: String,
	active: Boolean,
	section: String,
	question_id: String,
	status: String,
	question_text: String,
	subquestion_text: String,
	tooltip: String,
	notes: String,
	conditions: String,
	type: String,
});

let bools = computed(() => {
	return [
	].filter((a) => props[a]);
});

const auth = useAuthStore();
</script>
