<template>
    
    <v-snackbar v-model="value" text :color="error ? 'error' : 'primary'" >
        {{message}}
        <v-btn text @click="value = false" icon><v-icon>mdi-close</v-icon> </v-btn>
    </v-snackbar>
</template>

<script>
export default {
    name: "ToastMessage", 
    data: () => {
        return {
            value: false, 
            message: "",
            error: null
        }
    }, 
	created() {
		// const self = this;
		// self.$root.$on("showToast", (v, err = null) => {
        //     if( err ){
        //         self.error = true;
        //     }
        //     else{
        //         self.error = false;
        //     }
		// 	self.message = v;
		// 	self.value = true;
		// });
	}
}
</script>