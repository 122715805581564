<template>
	<v-sheet class="pa-2">
		<h3 class="mb-3">{{ question_text }}</h3>

		<div class="mb-3" v-if="type">
			<type-chip color="success" :value="type"></type-chip>
		</div>
		<p v-if="subquestion_text" class="mb-3">
			<b>Subquestion</b>: {{ subquestion_text }}
		</p>
		<p class="primary--text mb-2" v-if="conditions">
			<i>{{ conditions }}</i>

		</p>
		<type-chip
			v-for="s in bools"
			:key="s"
			:value="s"
			size="small"
			class="ml-1"
			color="accent"
		></type-chip>
		<v-card
			v-if="tooltip"
			flat
			class="sidebar-card"
			color="lightest"
			title="Tooltip Content"
			:text="tooltip"
		></v-card>
		<v-card
			v-if="notes"
			flat
			class="sidebar-card"
			title="Notes"
			:text="notes"
		></v-card>
	</v-sheet>
</template>

<style lang="less">
.sidebar-card {
	margin-top: 15px;
	.v-card-title {
		font-size: 0.8rem;
		line-height: 1rem;
	}
}
</style>
<script setup>
import { defineProps } from 'vue';
import TypeChip from '@c/editor/TypeChip';
const props = defineProps({
	status: String,
	question_text: String,
	subquestion_text: String,
	tooltip: String,
	notes: String,
	conditions: String,
	type: String,
});

const bools = [].filter((a) => props[a]);

</script>
