<template>
	<div>
		<draggable :list="dma.sections" item-key="index" @change="saveSectionInDma" :disabled="disabledDrag">
			<template #item="{ element, index }">
				<section-list-item
					:id="element"
					:key="index"
					:active="current == element"
					v-bind="sections[element]"
					@click="$emit('select',element)"
				>
				</section-list-item>
			</template>
		</draggable>
	</div>
</template>

<script setup>
import { computed, defineProps } from 'vue';
import { useDmaStore } from '@/stores/dma';
import { useAuthStore } from '@/stores/auth';
import { useSectionsStore } from '@/stores/sections';
import SectionListItem from '@c/editor/SectionListItem';

defineProps({
    current: String
})

const dma = useDmaStore();
const sectionsStore = useSectionsStore();
const auth = useAuthStore();
let sections = computed(() => {
	return sectionsStore.data;
});

import draggable from 'vuedraggable';

let disabledDrag = computed( () => {
	return !(auth.isAdmin && dma.status == 'open')
})

const saveSectionInDma = () => {
	dma.update('sections');
};
</script>
