<template>
	<v-navigation-drawer
		touchless
		permanent
		v-model="drawer"
		:mini-variant="mini"
	>
		<template v-slot:prepend>
			<slot name="prepend"></slot>
		</template>
		<v-list dense>
			<slot></slot>
		</v-list>
		<template v-slot:append>
			<slot name="append"></slot>
		</template>
	</v-navigation-drawer>
</template>
<script setup>
import { ref } from 'vue';
// import NavItem from '@c/navigation/Item';
let mini = ref(false);
let drawer = ref(true);
</script>
