import { defineStore } from 'pinia';
import { useCollection } from 'vuefire';
import { addDoc } from 'firebase/firestore';
import { commentsRef } from '../../firebase';
import { computed, ref } from 'vue';
import { useAuthStore } from './auth';
export const useCommentsStore = defineStore('comments', () => {
	const collection = useCollection(commentsRef);
	const data = computed(() => {
		return Object.fromEntries(
			collection.value.map((v) => {
				v.ref = v.id;
				return [v.id, v];
			})
		);
	});
	const create = async (doc) => {
		const auth = useAuthStore();
		doc.created_at = new Date();
		doc.created_by = auth.id;
		let newRef = await addDoc(commentsRef, doc);
		return newRef;
	};
	const byQuestion = computed(() => {
		return (id) => {
			return collection.value
			.filter((c) => c.question == id)
			.sort( (a, b ) => {
				return a.created_at.toDate() > b.created_at.toDate()  
			})
		}
	});
	let types = ref([{value: 'edit', name: "Edit Question"}, {value: 'remove', name: "Remove Question"}, {value: 'move', name: "Move Question"}]);
	return { collection, data, create, byQuestion, types };
});
