import { defineStore } from 'pinia';
import { doc, getDoc, updateDoc, arrayUnion, arrayRemove } from 'firebase/firestore';
import { db } from '../../firebase';
import { useAuthStore } from './auth';

export const useDmaStore = defineStore({
	id: 'dma',
	state: () => {
		return {
			id: null,
			name: '',
			status: 'review',
			sections: []
		};
	},
	getters: {
	},
	actions: {
		async init(id) {
			this.id = id;
			this.docRef = doc(db, 'dmas', this.id);
			let dma = await getDoc(this.docRef);
			if (dma.exists()) {
				this.setData(dma.data());
			} else {
				// TODO: Some kind of errorehre
			}
			return;
		},
		async addSection(id){
			const auth = useAuthStore();
			let update = {
				updated_at: new Date(),
				updated_by: auth.id
			}
			update.sections = arrayUnion(id);
			this.sections.push( id );
			return await updateDoc(this.docRef, update);
		},
		async deleteSection(id){
			const auth = useAuthStore();
			let update = {
				updated_at: new Date(),
				updated_by: auth.id
			}
			update.sections = arrayRemove(id);
			this.sections = this.sections.filter( id2 => id !== id2 );
			return await updateDoc(this.docRef, update);
		},
		async update(key){
			const auth = useAuthStore();
			let update = {
				updated_at: new Date(),
				updated_by: auth.id
			}
			update[key] = this[key];
			return await updateDoc(this.docRef, update);
		},
		setData(obj) {
			for (var key in obj) {
				this[key] = obj[key];
			}
		},
	}
});
