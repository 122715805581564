<template>
	<v-dialog v-model="dialog">
		<template v-slot:activator="{ props }">
			<v-btn color="primary" v-bind="props" :block="block"> Add Comment </v-btn>
		</template>
		<v-card flat class="pa-2" title="New Comment">
			<v-card-text>
				<v-form ref="form">
					<v-autocomplete
						label="Type of comment"
						:modelValue="type"
						item-title="name"
						item-value="value"
						:return-object="false"
						@update:modelValue="(v) => (type = v)"
						:items="commentsStore.types"
					></v-autocomplete>
					<v-select
						label="Move to section"
						:modelValue="move_to"
						@update:modelValue="(v) => (move_to = v)"
						:items="sections"
						item-title="name"
						item-value="id"
						:return-object="false"
						v-if="type == 'move'"
					></v-select>

					<v-textarea label="Rationale" v-model="rationale"></v-textarea>
					<v-textarea
						label="Comment / text suggestion"
						v-model="suggestion"
						v-if="['add', 'edit'].includes(type)"
					></v-textarea>

					<v-select
						v-if="'edit' == type"
						label="Will this change break this question's comparability with the 2019 DMA?"
						:modelValue="breaking"
						@update:modelValue="(v) => (breaking = v)"
						:items="breakingItems"
						item-title="text"
						item-value="value"
						:return-object="false"
					></v-select>
				</v-form>
			</v-card-text>
			<v-card-actions>
				<v-btn color="secondary" @click="dialog = false">Cancel</v-btn>
				<v-spacer></v-spacer>
				<v-btn color="success" size="small" @click="addComment">Submit</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script setup>
import { useCommentsStore } from '@/stores/comments';
import { useSectionsStore } from '@/stores/sections';
import { defineProps, ref, computed } from 'vue';
const commentsStore = useCommentsStore();
const props = defineProps({
	question: String,
	section: String,
	block: Boolean
});
const sectionsStore = useSectionsStore();

let sections = computed(() => {
	return [{id: 'new', name: "New Section"}].concat(sectionsStore.collection)
});

let dialog = ref(false);
let form = ref();
let type = ref(null);
let move_to = ref(null);
let rationale = ref(null);
let suggestion = ref(null);
let breaking = ref(null);
const breakingItems = [
	{ text: 'Yes', value: 'yes' },
	{ text: 'No', value: 'no' },
	{ text: "Don't know", value: 'dk' }
];

const reset = () => {
	dialog.value = false;
	form.value.reset();
};

const addComment = () => {
	commentsStore
		.create({
			type: type.value,
			rationale: rationale.value,
			suggestion: suggestion.value, 
			section: props.section,
			move_to: move_to.value,
			breaking: breaking.value, 
			question: props.question
		})
		.then(() => {
			reset();
		});
};
</script>
