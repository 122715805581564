<template>
	<span v-if="haveComments">
		<v-badge color="success" :content="commentCount">
		<v-icon>mdi-comment</v-icon>

		</v-badge>
	</span>
</template>

<script setup>
	import { defineProps, computed } from "vue";
	import { useCommentsStore } from '@/stores/comments';
	const commentsStore = useCommentsStore();
	const props = defineProps({
		id: String
	})

	let comments = computed( () => {
		return commentsStore.byQuestion(props.id)
	})

	let commentCount = computed( () =>  comments.value.length) 
	let haveComments = computed( () =>  commentCount.value > 0 ) 
</script>
