<template>
	<div v-if="!isClosed">
		<icon-button-with-confirm
			
			icon="mdi-delete"
			size="medium"
			@click="deleteItem"
		></icon-button-with-confirm>
		<edit-question :id="id" :section="section"></edit-question>
	</div>
</template>

<script setup>
import { defineProps, computed } from 'vue';
import { useSectionsStore } from '@/stores/sections';
import { useDmaStore } from '@/stores/dma';

import IconButtonWithConfirm from '@c/buttons/IconButtonWithConfirm';
import EditQuestion from '@c/admin/EditQuestion';
const sectionsStore = useSectionsStore();
const props = defineProps({
	id: String,
	section: String
});

const dma = useDmaStore();

let isClosed = computed(() => dma.status == 'closed');

const deleteItem = function () {
	sectionsStore.deleteQuestionFromSection(props.section, props.id);
};
</script>
