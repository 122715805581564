<template>
	<div class="pa-1">
		<v-dialog v-model="dialog">
			<template v-slot:activator="{ props }">
				<v-btn block color="primary" v-bind="props"> Edit Section </v-btn>
			</template>
			<v-card>
				<v-card-title> Edit Section </v-card-title>
				<v-card-text>
					<v-form>
						<section-form
							v-model:name="name"
							v-model:theme="theme"
							v-model:description="description"
						></section-form>
					</v-form>
				</v-card-text>
				<v-card-actions>
					<v-btn color="secondary" @click="dialog = false">Cancel</v-btn>
					<v-spacer></v-spacer>
					<v-btn color="primary" @click="submit">Submit</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script setup>
import { ref, computed, defineProps, watch } from 'vue';
import { useSectionsStore } from '@/stores/sections';
import SectionForm from '@c/admin/SectionForm';
const sectionsStore = useSectionsStore();
const props = defineProps({
	id: String
});
let dialog = ref(false);
let name = ref(null);
let theme = ref(null);
let description = ref(null);

let section = computed(() => {
	return sectionsStore.data[props.id];
});

watch( section, (value) => {
	name.value = value.name;
	theme.value = value.theme;
	description.value = value.description;
}, {immediate: true, deep: true } )


function submit() {
	sectionsStore
		.update( props.id, {
			name: name.value,
			theme: theme.value,
			description: description.value,
		})
		.then(() => {
			dialog.value = false;
		});
}
</script>
