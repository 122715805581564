<template>
	<v-card class="sidebar-card comment-card">
		<v-card-text>
			<v-table class="comment-table">
				<tr>
					<th>Created By</th>
					<td>
                        <user-name :id="created_by"></user-name>
                    </td>
				</tr>
				<tr v-if="rationale">
					<th>Rationale</th>
					<td>{{ rationale }}</td>
				</tr>
				<tr v-if="suggestion">
					<th>Comment / Suggestion</th>
					<td>{{ suggestion }}</td>
				</tr>
				<tr v-if="type">
					<th>Comment Type</th>
					<td><type-chip size="x-small" :value="type"></type-chip></td>
				</tr>
				<tr v-if="move_to">
					<th>Move To</th>
					<td>{{ sections[move_to] ? sections[move_to].name : ""  }}</td>
				</tr>
				<tr v-if="breaking">
					<th>Breaking Change</th>
					<td>{{ breaking }}</td>
				</tr>
			</v-table>
		</v-card-text>
		<v-card-actions>
			<v-spacer></v-spacer>
			<small class="text-overline lightest--text mr-2"
				><date-string :value="created_at"></date-string
			></small>
		</v-card-actions>
	</v-card>
</template>
<style lang="less">
.comment-card {
	// border: 2px solid rgba(0, 0, 0, 0.095);
	.v-card-actions {
		padding: 4px 4px !important;
	}
	.comment-table {
		font-size: 0.9em;
		th {
			text-align: left;
		}
	}
}
</style>
<script setup>
import { defineProps, computed } from 'vue';
import UserName from '@c/editor/UserName';
import DateString from '@c/editor/DateString';
import TypeChip from '@c/editor/TypeChip';
import { useSectionsStore } from '@/stores/sections';

const props = defineProps({
	id: String,
	created_by: String,
	created_at: Object,
	rationale: String,
	suggestion: String,
	type: String,
	breaking: String,
	section: String,
	move_to: String,
	answer_options: String
});

const sectionsStore = useSectionsStore();
let sections = computed( () => {
    return sectionsStore.data;
})
props;
</script>
