<template>
	<v-list-item @click="$emit('click', id)" :active="active" :class="{[theme]: true}">
		<v-list-item-title>{{ name }}</v-list-item-title>
		<template v-slot:append v-if="auth.isAdmin">
			<icon-button-with-confirm
				v-if="!isClosed"
				icon="mdi-close"
				size="x-small"
				@click="deleteItem"
			></icon-button-with-confirm>
		</template>
	</v-list-item>
</template>
<style lang="less">
	.v-list-item.readiness {
		border-left: 8px solid #00775B;
	}
	.v-list-item.infrastructure {
		border-left: 8px solid grey;
	}
	.v-list-item.capabilities {
		border-left: 8px solid #009FE3;
	}
</style>
<script setup>
import { useAuthStore } from '@/stores/auth';
import { useDmaStore } from '@/stores/dma';
import { defineProps, computed } from 'vue';
import IconButtonWithConfirm from '@c/buttons/IconButtonWithConfirm';

const props = defineProps({
	id: String,
	name: String,
	theme: String,
	active: Boolean
});
const auth = useAuthStore();
const dma = useDmaStore();

let isClosed = computed( () => dma.status == 'closed' );
const deleteItem = () => {
	dma.deleteSection(props.id);
};
</script>
