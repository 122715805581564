<template>
	<v-sheet>
		<side-navigation>
			<template v-slot:prepend>
				<switch-dma></switch-dma>
			</template>
			<section-list
				:current="currentSection"
				@select="(v) => (currentSection = v)"
			></section-list>
			<template v-slot:append>
				<template v-if="auth.isAdmin">
					<user-form></user-form>
					<create-section></create-section>
					<edit-section
						v-if="currentSection"
						:id="currentSection"
					></edit-section>
				</template>
			</template>
		</side-navigation>
		<secondary-navigation
			v-model="showRightHandDrawer"
			@close="showRightHandDrawer = false"
		>
			<question-sidebar
				v-if="currentQuestion"
				:id="currentQuestion"
				:section="currentSection"
			></question-sidebar>
		</secondary-navigation>
		<v-sheet>
			<template v-if="currentSection">
				<v-toolbar flat color="white">
					<v-spacer></v-spacer>
					<v-btn text @click="currentSection = null">Back to guide</v-btn>
				</v-toolbar>
				<v-alert v-if="dma.status == 'closed'" type="info" variant="tonal" class="mb-4">
					This question set is now closed for comments. Please email sg@dma.works if you think a mistake has been made.
				</v-alert>
				<questions-list
					:id="currentSection"
					:current="currentQuestion"
					@select="(v) => (currentQuestion = v)"
				></questions-list>
			</template>
			<template v-else>
				<guide-to-tool></guide-to-tool>
			</template>
			<template v-if="currentSection">
				<p class="text-right">
					<import-questions
						v-if="auth.isAdmin"
						:section="currentSection"
					></import-questions>
					<create-question
						v-if="auth.isAdmin"
						:section="currentSection"
					></create-question>
					<suggest-question :section="currentSection"></suggest-question>
				</p>
			</template>
		</v-sheet>
	</v-sheet>
</template>

<script setup>
import { ref, watch } from 'vue';
import { useAuthStore } from '@/stores/auth';
import SwitchDma from '@c/admin/SwitchDma';
import SectionList from '@c/editor/SectionList';
import SecondaryNavigation from '@c/navigation/SecondaryNavigation';
import SideNavigation from '@c/navigation/SideNavigation';
import QuestionsList from '@c/editor/QuestionsList';
import CreateSection from '@c/admin/CreateSection';
import EditSection from '@c/admin/EditSection';
import CreateQuestion from '@c/admin/CreateQuestion';
import ImportQuestions from '@c/admin/ImportQuestions';
import QuestionSidebar from '@c/editor/QuestionSidebar';
import SuggestQuestion from '@c/editor/SuggestQuestion';
import UserForm from '@c/admin/UserForm';
import GuideToTool from '@/views/GuideToTool';
import { useDmaStore } from '@/stores/dma';

const auth = useAuthStore();
const dma = useDmaStore();

let currentSection = ref(null);
let currentQuestion = ref(null);
let showRightHandDrawer = ref(false);

watch(currentQuestion, (value) => {
	if (value) {
		showRightHandDrawer.value = true;
	} else {
		showRightHandDrawer.value = false;
	}
});
watch(currentSection, (value) => {
	if (value) {
		currentQuestion.value = null;
	}
});
watch(showRightHandDrawer, (value) => {
	if (!value) {
		currentQuestion.value = null;
	}
});
</script>
