<template>

  <v-sheet>
    <dashboard-view v-if="auth.loggedIn"></dashboard-view>
    <about-view v-else></about-view>
  </v-sheet>
</template>

<script setup>
import { useAuthStore } from '@/stores/auth';
import DashboardView from "./DashboardView";
import AboutView from "./AboutView";
const auth = useAuthStore();
</script>
