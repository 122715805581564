// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const theme = {
   themes: {
    light: {
      colors: {
        lightest: "#f7f7f7",
        primary: "#124987",
        secondary: "#E4E4E4",
        accent: "#009FE3",
        error: "#b71c1c",
        green: "#00775B",
        lightblue: "#009FE3",
        darkblue: "#003087",
      }
    }
  }
};
const vuetify = createVuetify({
  theme,
  components,
  directives,
})

// Vuetify
export default vuetify;